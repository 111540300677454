<template>
  <base-section id="certificacion">
    <v-container class="mb-n15">
      <v-row>
        <v-col cols="12">
          <base-section-heading title="Recertificación">
          </base-section-heading>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <!--<base-title
            class="red--text"
            title="¿Qué es la Recertificación ?"
            tag="div"
            style="color: red"

          />-->

          <h2 class="font-weight-bold mb-0 red--text text-left mb-4" >
            ¿Qué es la Recertificación ?
          </h2>

          <div class="font-weight-bold" style="text-align: justify; color: #636363">
            <p>Es el proceso de evaluación a un médico especialista certificado en alguna rama de la medicina que permite razonablemente asegurar que ha continuado con su preparación, que mantiene al día sus conocimientos y que sabe utilizar la tecnología para atender eficazmente y con calidad a sus pacientes.</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionReCertificacion',

    data: () => ({

    }),
  }
</script>
